.navbar {
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand img {
  height: 40px;
  width: auto;
}

.nav-link {
  color: #343a40;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #007bff;
}

.nav-link.disabled {
  color: #6c757d;
}

.navbar-toggler {
  border: none;
}


.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=UTF8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath stroke="rgba%280, 0, 0, 0.5%29" stroke-width="2" linecap="round" linejoin="round" d="M4 7h22M4 15h22M4 23h22"%3E%3C/path%3E%3C/svg%3E');
}
li :hover{
  border: 10px black;
  border-radius: 10px ;
  background-color: #b4ba5e;

}
